import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from '/@/layout/App';
import { GlobalContext, GlobalContextValue } from './context/GlobalContext';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
	<StrictMode>
		<GlobalContext.Provider value={GlobalContextValue}>
			<BrowserRouter>
			<App />
			</BrowserRouter>
		</GlobalContext.Provider>
	</StrictMode>
);


document.body?.classList.remove('loading');
