
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '/@/components/Header/Header';
import Footer from '/@/components/Footer/Footer';
import './App.scss';

const Home = React.lazy(() => import('/@/pages/Home/Home'));
const Nutrition = React.lazy(() => import('/@/pages/Nutrition/Nutrition'));
const PestManagement = React.lazy(() => import('/@/pages/PestManagement/PestManagement'));
const Kozeg = React.lazy(() => import('/@/pages/Kozeg/Kozeg'));
const GardeningMaschines = React.lazy(() => import('/@/pages/GardeningMaschines/GardeningMaschines'));
const SmallGardenSolutions = React.lazy(() => import('/@/pages/SmallGardenSolutions/SmallGardenSolutions'));
const Partners = React.lazy(() => import('/@/pages/Partners/Partners'));
const Webshop = React.lazy(() => import('/@/pages/Webshop/Webshop'));
const Locations = React.lazy(() => import('/@/pages/Locations/Locations'));

const App = () => {

	const location = useLocation();

	const [footerVisible, setFooterVisible] = useState<boolean>(false);

	useEffect(() => {
		setFooterVisible(false);
		setTimeout(() => {
			setFooterVisible(true);
		}, 1000);
	}, [location]);

	return (
		<div>
			<Header></Header>
			<main>
				<Routes>
					<Route path="/" element={
						<React.Suspense fallback={<>...</>}>
							<Home />
						</React.Suspense>
					}></Route>

					<Route path="/tapanyagellatas" element={
						<React.Suspense fallback={<>...</>}>
							<Nutrition />
						</React.Suspense>
					}></Route>
					<Route path="/novenyvedelem" element={
						<React.Suspense fallback={<>...</>}>
							<PestManagement />
						</React.Suspense>
					}></Route>
					<Route path="/kerteszeti-kozegek" element={
						<React.Suspense fallback={<>...</>}>
							<Kozeg />
						</React.Suspense>
					}></Route>
					<Route path="/kerteszeti-gepek" element={
						<React.Suspense fallback={<>...</>}>
							<GardeningMaschines />
						</React.Suspense>
					}></Route>
					<Route path="/kiskerti-megoldasok" element={
						<React.Suspense fallback={<>...</>}>
							<SmallGardenSolutions />
						</React.Suspense>
					}></Route>
					<Route path="/partnereink" element={
						<React.Suspense fallback={<>...</>}>
							<Partners />
						</React.Suspense>
					}></Route>
					<Route path="/webaruhazunk" element={
						<React.Suspense fallback={<>...</>}>
							<Webshop />
						</React.Suspense>
					}></Route>
					<Route path="/telephelyeink" element={
						<React.Suspense fallback={<>...</>}>
							<Locations />
						</React.Suspense>
					}></Route>
				</Routes>
			</main>
			{ footerVisible &&  <Footer></Footer> }
		</div>
	)
}

export default App
