type Props = {
	obj: any
}

const DummyComponent = (props: Props) => {

	return (
		<h1>I am dummy component</h1>
	)
}

export default DummyComponent;
